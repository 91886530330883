<template lang="pug" functional>
  .right-panel-empty-state
    img.right-panel-empty-state__icon(src="@/assets/ui/select-department.svg")
    h3.right-panel-empty-state__title {{ 'infotext.please_select_an_element_to_see_details' | translate }}

</template>

<script>
export default {
  name: 'RightPanelEmptyState'
}
</script>

<style lang="scss">
  .right-panel-empty-state {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__icon {
      margin-bottom: 15px;
    }

    &__title {
      color: #B3B8CE;
      font-size: 22px;
      text-align: center;
      width: 70%;
    }
  }
</style>
